import React, { useEffect, useState, useMemo, forwardRef, useCallback } from "react";
import axios from "axios";
import Spinner from "react-spinner-material";
import { TABLE_TEXT_COLOR } from "../../constants/colors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as systemActions from "../../actions/systemActions";
import { ReactComponent as ArrowDown } from "./../../assets/icons/arrow-down.svg";
import { useSelector, useDispatch } from "react-redux";
import html2pdf from "html2pdf.js";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

const API_URL = process.env.REACT_APP_API_URL;

const Programmatic = () => {
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [companyName, setCompanyName] = useState("Company");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const pageSize = 50;

  const currentDate = new Date();
  const defaultDateTo = new Date();
  const defaultDateFrom = new Date(currentDate.setDate(currentDate.getDate() - 14));
  const dispatch = useDispatch();

  const [dateFrom, setDateFrom] = useState(defaultDateFrom);
  const [dateTo, setDateTo] = useState(defaultDateTo);

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const tableHeadings1 = [
    { name: "Campaign", key: 'campaign' },
    { name: "Impressions", key: 'impressions' },    
    { name: "CPM", key: 'cpm' },
    { name: "Clicks", key: 'clics' },
    { name: "CPC", key: 'ecpc' },
    { name: "CTR", key: 'ctr' },
    { name: "Total Conversions", key: 'total_conversions' },
    { name: "REGs", key: 'reg' },
    { name: "DCs", key: 'dc' },
    { name: "FTDs", key: 'ftd' },
    { name: "CPA (DCs)", key: 'cpa_dc' },
    { name: "CPA (FTDs)", key: 'cpa_ftd' },
    { name: "Net Spend", key: 'net_spend' },  
    { name: "Agency fee", key: 'agency_fee' },  
    { name: "Gross Spend", key: 'gross_spend' },  
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setSpinner(true);
        const response = await axios.post(`${API_URL}/trade-desk`, {
          dateFrom: dateFrom.toISOString().split('T')[0],
          dateTo: dateTo.toISOString().split('T')[0],
          page: currentPage,
          pageSize: pageSize
        });

        const flattenedData = response.data.data.map(item => ({
          id: item.id,
          item_date: item.item_date,
          competitor: item.companies_id?.name || "N/A",
          campaign: item.campaigns_id?.name || "N/A",
          cost: item.cost,
          impressions: item.impressions,
          cpm: "€ " + (item.cost / item.impressions * 1000).toFixed(2),
          ctr: (item.clics / item.impressions * 100).toFixed(2) + '%',
          clics: item.clics,
          ecpc: "€ " + (item.cost / item.clics).toFixed(2),
          total_conversions: item.reg + item.ftd + item.dc,
          cpa: item.cpa.toFixed(2),
          reg: item.reg,
          ftd: item.ftd,
          dc: item.dc,
          cpa_reg: "€ " + (item.cost / item.reg).toFixed(2),
          cpa_ftd: "€ " + (item.cost / item.ftd).toFixed(2),
          cpa_dc: "€ " + (item.cost / item.dc).toFixed(2),
          net_spend: "€ " + item.cost,
          agency_fee: "€ " + (item.cost * 0.11),
          gross_spend: "€ " + (item.cost + item.cost * 0.11),
        }));

        setData(flattenedData);
        setTotalPages(Math.ceil(response.data.total / pageSize));

        if (flattenedData.length > 0) {
          const firstRow = flattenedData[0];
          setCompanyName(firstRow.competitor);
        }
      } catch (error) {
        console.error("Error fetching data from API:", error);
      } finally {
        setSpinner(false);
      }
    };

    fetchData();
  }, [dateFrom, dateTo, currentPage]);

  const handleDateFromChange = (date) => {
    setDateFrom(date);
    setCurrentPage(1);
  };

  const handleDateToChange = (date) => {
    setDateTo(date);
    setCurrentPage(1);
  };

  const filteredData = useMemo(() => {
    if (selectedCampaigns.length === 0 || selectedCampaigns.includes("")) {
      return data;
    }
    return data.filter(row => selectedCampaigns.includes(row.campaign));
  }, [data, selectedCampaigns]);

  const totalRow = useMemo(() => {
    if (filteredData.length === 0) return null;
    const total = filteredData.reduce((acc, row) => {
      Object.keys(row).forEach(key => {
        if (typeof row[key] === "number") {
          acc[key] = (acc[key] || 0) + row[key];
        }
      });
      return acc;
    }, { campaign: "Total", competitor: "" });

    total.cpm = "€ " + (total.cost / total.impressions * 1000).toFixed(2);
    total.ctr = (total.clics / total.impressions * 100).toFixed(2) + '%';
    total.ecpc = "€ " + (total.cost / total.clics).toFixed(2);
    total.cpa_reg = "€ " + (total.cost / total.reg).toFixed(2);
    total.cpa_ftd = "€ " + (total.cost / total.ftd).toFixed(2);
    total.cpa_dc = "€ " + (total.cost / total.dc).toFixed(2);
    total.net_spend = "€ " + total.cost.toFixed(2);
    total.agency_fee = "€ " + (total.cost * 0.11).toFixed(2);
    total.gross_spend = "€ " + (total.cost + total.cost * 0.11).toFixed(2);

    return total;
  }, [filteredData]);

  const exportToExcel = () => {
    const exportData = filteredData.map((row) => {
      const newRow = {};
      tableHeadings1.forEach((heading) => {
        newRow[heading.name] = row[heading.key];
      });
      return newRow;
    });
  
    if (totalRow) {
      const totalRowFormatted = {};
      tableHeadings1.forEach((heading) => {
        totalRowFormatted[heading.name] = totalRow[heading.key] || "";
      });
      exportData.push(totalRowFormatted);
    }
  
    const worksheet = XLSX.utils.json_to_sheet(exportData);
  
    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
        if (!worksheet[cellAddress]) continue;
  
        worksheet[cellAddress].s = {
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
          alignment: { vertical: "center" },
        };
  
        if (row === range.e.r) {
          worksheet[cellAddress].s.border = {
            top: { style: "medium" },
            bottom: { style: "medium" },
            left: { style: "medium" },
            right: { style: "medium" },
          };
        }
  
        if (row === 0) {
          worksheet[cellAddress].s.font = { bold: true };
          worksheet[cellAddress].s.alignment = {
            horizontal: "center",
            vertical: "center",
          };
        }
      }
    }
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Programmatic Data");
  
    const fromDate = dateFrom.toISOString().split("T")[0];
    const toDate = dateTo.toISOString().split("T")[0];
    const fileName = `programmatic_data_${fromDate}_to_${toDate}.xlsx`;
  
    XLSX.writeFile(workbook, fileName);
  };
  

  const printToPdf = () => {
    const element = document.querySelector(".App");
    const options = {
      filename: 'programmatic-report.pdf',
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }
    };

    const elementsToExclude = document.querySelectorAll(".exclude-pdf");
    elementsToExclude.forEach((el) => {
      el.style.display = "none";
    });    

    html2pdf().from(element).set(options).save().then(() => {
      elementsToExclude.forEach((el) => {
        el.style.display = "";
      });
    });
  };

  const ExampleCustomInput = forwardRef(({ date, label, onClick }, ref) => {
    return (
      <button ref={ref} onClick={onClick} className="example-custom-input-1" style={{ backgroundColor: "white" }}>
        {label}: {date.toLocaleDateString()}
      </button>
    );
  });

  return (
  <>
    <style>
      {`
        .timezone2-date-picker-right {
          position: absolute;
          right: 245px !important;
        }

        .timezone2-date-picker-right2 {
          position: absolute;
          right: 125px !important;
        }

        .campaign-checkbox-list {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
          padding: 10px;
          width: 100%;
          max-width: 100%;
        }

        .campaign-checkbox-list label {
          margin-bottom: 0;
          white-space: nowrap;
          font-size: 12px;
        }

        .campaign-checkbox-list input[type="checkbox"] {
          margin-right: 5px;
        }

      `}
    </style>    
    <div className="App atl competitors-1">
      <div className="page-content-container">
        <div className="title-container">
          <h2 className="title overview-title">
            {companyName}, Programmatic, {dateFrom.getDate()} {monthNames[dateFrom.getMonth()]} {dateFrom.getFullYear()} - {dateTo.getDate()} {monthNames[dateTo.getMonth()]} {dateTo.getFullYear()}
          </h2>
        </div>

        <div className="top-buttons exclude-pdf" style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
        <button
          onClick={printToPdf}
          className="btn btn-white download-as-pdf"
          style={{
            width: '100px',
            position: 'absolute',
            top: '15px',
            right: '360px'
          }}
        >
          PDF &nbsp; <ArrowDown />
        </button>        
        <button
          onClick={exportToExcel}
          className="btn btn-white export-to-excel"
          style={{
            width: '40px',
            height: '30px',
            position: 'absolute',
            top: '15px',
            right: '470px'
          }}
        >
          <FontAwesomeIcon icon={faFileExcel} style={{ color: "green" }} />
        </button>        
          <DatePicker
            selected={dateFrom}
            onChange={handleDateFromChange}
            dateFormat="yyyy-MM-dd"
            showPopperArrow={false}
            wrapperClassName="timezone2-date-picker-right"
            popperPlacement="bottom-start"
            customInput={<ExampleCustomInput date={dateFrom} label="From" className={"example-custom-input-1"} style={{ backgroundColor: "white" }} />}
          />
          <DatePicker
            selected={dateTo}
            onChange={handleDateToChange}
            dateFormat="yyyy-MM-dd"
            showPopperArrow={false}
            wrapperClassName="timezone2-date-picker-right2"
            popperPlacement="bottom-start"
            customInput={<ExampleCustomInput date={dateTo} label="To" className={"example-custom-input-1"} style={{ backgroundColor: "white" }} />}
          />
        </div>

        <div style={{ marginBottom: "20px" }} className="exclude-pdf">
          <label htmlFor="campaignFilter" style={{ marginBottom: "10px", fontWeight: "bold", display: "block" }}>
            Filter by Campaign:
          </label>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              padding: "10px",
              width: "97%",
              maxWidth: "100%",
            }}
          >
            <label style={{ marginBottom: "0", whiteSpace: "nowrap" , fontSize:"12px" }}>
              <input
                type="checkbox"
                value=""
                checked={selectedCampaigns.length === 0}
                onChange={() => setSelectedCampaigns([])}
                style={{ marginRight: "5px", verticalAlign: "middle" }}
              />
              All Campaigns
            </label>
            {[...new Set(data.map(row => row.campaign))].map((campaign, index) => (
              <label key={index} style={{ marginBottom: "0", fontSize:"12px", whiteSpace: "nowrap" }}>
                <input
                  type="checkbox"
                  value={campaign}
                  checked={selectedCampaigns.includes(campaign)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedCampaigns([...selectedCampaigns, campaign]);
                    } else {
                      setSelectedCampaigns(selectedCampaigns.filter(c => c !== campaign));
                    }
                  }}
                  style={{ marginRight: "5px", verticalAlign: "middle" }}
                />
                <span>{campaign}</span>
              </label>
            ))}
          </div>
        </div>
          
        <div className="chart-container table" style={{ borderRadius: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {spinner ? (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <Spinner
                  radius={40}
                  color={TABLE_TEXT_COLOR}
                  stroke={3}
                  visible={true}
                />
                <p>Loading data...</p>
              </div>
            ) : (
              filteredData && filteredData.length > 0 ? (
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      {tableHeadings1.map((heading, index) => (
                        <th key={index} style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                          {heading.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((row, rowIndex) => (
                      <tr key={rowIndex} style={{ display: "table-row" }}>
                        {tableHeadings1.map((heading, colIndex) => (
                          <td
                            key={colIndex}
                            style={{
                              display: "table-cell",
                              borderBottom: "1px solid #ddd",
                              padding: "8px",
                            }}
                          >
                            {row[heading.key]}
                          </td>
                        ))}
                      </tr>
                    ))}
                    {totalRow && (
                      <tr style={{ fontWeight: "bold", display: "table-row"}}>
                        {tableHeadings1.map((heading, colIndex) => (
                          <td
                            key={colIndex}
                            style={{
                              display: "table-cell",
                              borderTop: "2px solid black",
                              padding: "8px",
                            }}
                          >
                            {totalRow[heading.key] || ""}
                          </td>
                        ))}
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                <p style={{ textAlign: "center", padding: "20px" }}>
                  No data available.
                </p>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  </> 
  );
};

export default Programmatic;
